<template>
	<div class="pl20 pt20 pr20 pb20">
		<div class="tc">
			<span class="fs30 fw_b">未命名主题</span>
			<el-tag size="small" type="danger">2021-6-12</el-tag>
		</div>
		<ul class="flex_r f_jc_c f_ai_c pt30 pb30">
			<li>
				<span>主播：</span>
				<span class="cr_999 pr20">xxxx</span>
			</li>
			<li>
				<span>负责人：</span>
				<span class="cr_999 pr20">xxxx</span>
			</li>
			<li>
				<span>距离活动开始：</span>
				<span class="cr_999 pr20">xxxx</span>
			</li>
			<el-button class="ml100" @click="$refs.dialoEditgRef.handleOpen()">修改排期</el-button>
		</ul>
		<ul class="flex_r f_jc_b f_ai_c">
			<div class="flex_r f_ai_c bg_default br_1_eee pt10 pb10 pl20 pr20">
				<li class="mr40">
					<span class="fs16 cr_999">排期商品：</span>
					<span>1</span>
				</li>
				<li class="mr40">
					<span class="fs16 cr_999">待终审商品：</span>
					<span>1</span>
				</li>
				<li class="mr40">
					<span class="fs16 cr_999">已终审商品：</span>
					<span>1</span>
				</li>
			</div>
			<div class="fr ml60 cur_p">
				<span class="pr10" @click="showData = !showData">查看数据</span>
				<i :class="!showData ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
			</div>
		</ul>
		<el-row gutter="10" v-if="showData">
			<el-col :span="8">
				<e-card title="商品类目占比">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="ideeePie" :options="optionPie" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
			<el-col :span="4">
				<e-card title="类目商品数">
					<ul class="flex_r f_ai_c f_jc_b br_b_1_eee pb5 pt5">
						<li class="pl10 pr10 pt4 pb4">类目</li>
						<li class="pl10 pr10 pt4 pb4">商品数</li>
					</ul>
					<ul class="flex_r f_ai_c f_jc_b br_b_1_eee pb5 pt5">
						<li class="pl10 pr10 pt4 pb4">女装</li>
						<li class="pl10 pr10 pt4 pb4">1</li>
					</ul>
				</e-card>
			</el-col>
			<el-col :span="12">
				<e-card title="价格分布">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idqqPie" :options="optionLine" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
		</el-row>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="本场商品" name="1"></el-tab-pane>
			<el-tab-pane label="本场移除商品" name="2"></el-tab-pane>
			<el-tab-pane label="待排期商品" name="3"></el-tab-pane>
			<el-tab-pane label="其他场商品" name="4"></el-tab-pane>
			<el-tab-pane label="补播商品" name="5"></el-tab-pane>
			<el-tab-pane label="意向商品" name="6"></el-tab-pane>
		</el-tabs>
		<el-form label-width="auto" :inline="true">
			<el-form-item label="类目">
				<el-select>
					<el-option value="1" label="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="名称">
				<el-input></el-input>
			</el-form-item>
			<el-button type="primary">搜索</el-button>
			<el-button>导出商品</el-button>
			<el-button>导出手卡</el-button>
		</el-form>
		<div class="table_title flex_r bg_table mb10">
			<span>审核状态</span>
			<span>商品信息</span>
			<span>价格</span>
			<span>优惠</span>
			<span>福利</span>
			<span>试样记录</span>
			<span>操作</span>
		</div>
		<div class="br_1_eee">
			<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
				<div>
					<el-checkbox></el-checkbox>
					<span class="pl20 pr20">意向排期：</span>
					<span>商品来源：商家报名</span>
				</div>
				<div>
					<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
					<span>INNSO悦赏旗舰店</span>
				</div>
				<div class="flex_c f_jc_c f_ai_c" @click="flagHandle">
					<i class="el-icon-s-flag fs16 cr_eee"></i>
					<span>标记</span>
				</div>
			</div>
			<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-select class="">
						<el-option label="选项1" value="1"></el-option>
					</el-select>
					<div>
						<span>排期：</span>
						<el-date-picker
							style="width:150px"
							class="mt10"
							v-model="value1"
							type="date"
							placeholder="选择排期">
						</el-date-picker>
					</div>
					<span>活动：</span>
				</li>
				<li class="br_r_1_eee flex_r">
					<el-popover
						trigger="hover">
						<img height="300" width="300" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">
						<img slot="reference" height="80" width="80" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">
					</el-popover>
					<div class="pl10 pr10">
						<el-link class="lh_12 ellipsis_2" type="primary">零限提臀裤健身高腰提臀显瘦蜜桃翘臀塑形瑜伽紧身裤女外穿弹力裤</el-link>
						<el-select class="mt10">
							<el-option label="选项1" value="1"></el-option>
						</el-select>
						<div class="pt10">
							<span>规格：</span>
							<span class="cr_999 ellipsis_2 w_50">XS1111111111</span>
						</div>
					</div>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-input placeholder="请输入直播价" disabled v-model="input1">
						<template slot="prepend">直播价</template>
					</el-input>
					<el-input placeholder="请输入在售价" disabled v-model="input1">
						<template slot="prepend">在售价</template>
					</el-input>
					<el-input placeholder="请输入在售价" v-model="input1">
						<template slot="prepend">历史最低价</template>
					</el-input>
				</li>
				<li class="br_r_1_eee">
					<el-input :rows="6" type="textarea" placeholder=""></el-input>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-input :rows="3" type="textarea" placeholder="赠品："></el-input>
					<el-input :rows="3" type="textarea" placeholder="抽奖："></el-input>
				</li>
				<li class="br_r_1_eee">
					<el-input :rows="6" type="textarea" placeholder=""></el-input>
				</li>
				<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
					<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
					<router-link tag="a" target="_blank" :to="{path:'/goodsDetail'}">报名详情</router-link>
					<router-link tag="a" target="_blank" :to="{path:'/handCardDetail'}">查看手卡</router-link>
					<span class="cur_p" @click="$refs.dialogDataAddRef.handleOpen()">加入排期</span>
				</li>
			</ul>
			<ul v-if="toggle" class="br_t_1_eee pl40 pr40 pt10 tc">
				<li class="toggle flex_r f_jc_b lh12">
					<span>合同状态</span>
					<span>服务费</span>
					<span>保证金</span>
					<span>佣金链接</span>
					<span>样品</span>
				</li>
				<li  class="toggle flex_r f_jc_b">
					<div @click="sponsorHandle(1)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(2)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(3)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(4)">
						<span class="pr10">待提交</span>
						<!-- <el-link class="" type="primary">发起</el-link> -->
					</div>
					<div @click="sponsorHandle(5)">
						<span class="pr10">待发起寄送</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
				</li>
			</ul>
			<div class="item_title bg_fff flex_r f_ai_c f_jc_c tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee cr_999 cur_p">
				<div @click="toggle = !toggle">
					<i :class="toggle ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
					<span>{{toggle ? '收起' : '展开'}}</span>
				</div>
			</div>
		</div>
		<el-pagination
			class="tr"
			:small="true"
			:current-page.sync="currentPage"
			:page-size="pageSize"
			:total="total">
		</el-pagination>
		<e-dialog :title="dialogTitle" ref="dialogRef">
			<el-form v-if="flag" label-width="auto">
				<el-form-item label="标记">
					<el-radio-group v-model="radio">
						<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_eee"></i></el-radio>
						<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_ff0"></i></el-radio>
						<el-radio :label="6"><i class="el-icon-s-flag fs18 cr_f18"></i></el-radio>
						<el-radio :label="9"><i class="el-icon-s-flag fs18 cr_18a"></i></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="标记信息">
					<el-input type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<e-form v-else :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog title="修改排班" ref="dialoEditgRef">
			<e-form :formConfList="editformConfList" />
		</e-dialog>
	</div>
</template>

<script>
import {formConfList, addScheduleTableConf, ActiveTableConf, editformConfList} from '@data/handCard'
import {optionPie, optionLine} from '@data/dashboard'
export default {
	data() {
		return {
			optionPie,
			optionLine,
			showData: false,
			activeName: '1',
			editformConfList,
			page: true,
			value: new Date(),
			form: {},
			formConfList,
			ActiveTableConf,
			formConfListItem: [],
			dataOrigin: {
				data: [
					{
					}
				]
			},
			currentPage: 1,
			pageSize: 10,
			total: 0,
			toggle: false,
			dialogTitle: '',
			flag: false,
			addScheduleTableConf,
			date: ''
		}
	},
	methods: {
		dialogOpen() {
			this.$refs.dialogRef.handleOpen();
		},
		flagHandle() {
			this.flag = true;
			this.dialogTitle = '标记';
			this.dialogOpen();
		},
		sponsorHandle(val) {
			switch(val) {
				case 1:
					this.dialogTitle = '通知商家签约';
					break
				case 2:
					this.dialogTitle = '新增服务费';
					break
				case 3:
					this.dialogTitle = '新增保证金';
					break
				case 4:
					this.dialogTitle = '佣金链接';
					break
				case 5:
					this.dialogTitle = '通知寄样';
					break
			}
			this.flag = false;
			this.formConfListItem = this.formConfList[val]; 
			this.dialogOpen();
		},
	}
}
</script>

<style lang="scss" scoped>
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 120px;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.toggle {
	span,
	div {
		width: 20%;
		line-height: 2;
	}
	&:nth-child(1) {
		background: #fff9f8;
	}
}
.item {
	height: 350px;
}
</style>